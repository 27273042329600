<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb
      :title="$t('contact_us')"
      :text1="$t('contact_us')"
      :text="$t('home')"
    />

    <div class="page-wrapper">
      <!-- Contact Us -->
      <section class="contact-section">
        <div class="container">
          <div class="section-inner-header contact-inner-header">
            <h6>{{ $t("contact_us") }}</h6>
            <p class="h2">
              {{ $t("contactContent") }}
              <span class="mx-2">
                <a
                  class="text-infoo"
                  href="mailto:midhospital@midocean.edu.km"
                  to="faq"
                  >midhospital@midocean.edu.km</a
                >
              </span>
            </p>
          </div>
          <!-- <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="section-inner-header contact-inner-header">
                <h6>Get in touch</h6>
                <h2>Have Any Question?</h2>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-map-pin"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Address</h4>
                    <p>8432 Mante Highway, Aminaport, USA</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-phone"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Phone Number</h4>
                    <p>+1 315 369 5943</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-mail"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Email Address</h4>
                    <p>midhospital@example.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 d-flex">
              <div class="card contact-form-card w-100">
                <div class="card-body">
                  <form action="javascript:;">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Your Name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Email Address</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Phone Number</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Services</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Services"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="mb-2">Message</label>
                          <textarea
                            class="form-control"
                            placeholder="Enter your comments"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group-btn mb-0">
                          <b-button
                            type="submit"
                            class="btn btn-primary prime-btn"
                            >Send Message</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </section>
      <!-- /Contact Us -->
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Contact Us",
      text: "Home",
      text1: "Contact Us",
    };
  },
};
</script>
