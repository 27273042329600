<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-10 mx-auto">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-8 mx-auto">
                    <div class="login-header">
                      <h3>{{ $t("login") }}</h3>
                    </div>
                    <div>
                      <div class="">
                        <div class="mb-3 form-focus">
                          <input
                            type="email"
                            placeholder=" "
                            class="form-control floating"
                            v-model="email"
                          />
                          <label class="focus-label">{{
                            $t("register.email")
                          }}</label>
                        </div>
                        <div class="mb-2" v-if="v$.email.$error">
                          <small
                            v-if="v$.email.required.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.requiredField") }}
                          </small>
                          <small
                            v-else-if="v$.email.email.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.email") }}
                          </small>
                        </div>
                      </div>
                      <div class="">
                        <div class="mb-3 form-focus">
                          <input
                            v-model="password"
                            type="password"
                            placeholder=" "
                            class="form-control floating"
                          />
                          <label class="focus-label">{{
                            $t("password")
                          }}</label>
                        </div>
                        <div v-if="v$.password.$error">
                          <small
                            v-if="v$.password.required.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.requiredField") }}
                          </small>
                        </div>
                      </div>
                      <div class="text-end">
                        <router-link
                          class="forgot-link"
                          to="/forgot-password"
                          >{{ $t("register.forgotPassword") }}</router-link
                        >
                      </div>
                      <b-button
                        class="btn btn-primary w-100 btn-lg login-btn"
                        :disabled="loading"
                        @click="submitForm"
                      >
                        <span v-if="!loading">{{ $t("login") }}</span>
                        <b-spinner v-else></b-spinner>
                      </b-button>
                      <!-- <div class="login-or">
                        <span class="or-line"></span>
                        <span class="span-or">or</span>
                      </div> -->
                      <div class="row social-login">
                        <!-- <div class="col-6">
                          <a href="javascript:;" class="btn btn-facebook w-100"
                            ><i class="fab fa-facebook-f me-1"></i> Login</a
                          >
                        </div> -->
                        <!-- <div class="col-6">
                          <a href="javascript:;" class="btn btn-google w-100"
                            ><i class="fab fa-google me-1"></i> Login</a
                          >
                        </div> -->
                      </div>
                      <div class="text-center dont-have">
                        {{ $t("dont_have_account") }}
                        <router-link to="/patient-register">{{
                          $t("registerName")
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <footerindex />
    </div>
    <teleport to="body">
      <a-modal
        v-model:open="open"
        :title="$t('notice')"
        centered
        :footer="null"
        @cancel="open = false"
      >
        <div class="mb-4">
          <small class="text-info">
            {{ $t("otpMSG") }}
          </small>
          <div class="pt-2 pb-3">
            <input class="form-control" v-model="code" />
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="btn btn-primary btn-lg login-btn d-block w-100"
              @click="submitForm"
            >
              <span>{{ $t("enterOtp") }}</span>
            </b-button>
          </div>
        </div>
      </a-modal>
    </teleport>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { login } from "@/api/user";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      email: "",
      password: "",
      code: "",
      loading: false,
      open: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  methods: {
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      this.loading = true;

      let payload = new FormData();

      payload.append("email", this.email);
      payload.append("password", this.password);
      this.code && payload.append("code", this.code);

      try {
        const res = await login(payload);
        if (res?.enable_2fa) {
          this.open = true;
          return null;
        }
        this.$store.dispatch("handleLogin", res);
        // console.log(res.enable_2fa, "ffffff");
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.loading = false;
      }

      // this.$store
      //   .dispatch("handleLogin", {
      //     action: "login",
      //     payload: payload,
      //     locale: this.$i18n.locale,
      //   })
      //   .then(() => {
      //     this.loading = false;
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //     this.loading = false;
      //   });
    },
  },
};
</script>
