export const isValidArabicContent = (htmlContent) => {
  if (!htmlContent) return true;
  const regex = /^[0-9\-.():، \n/_\u0600-\u06ff ]+$/;
  return regex.test(htmlContent);
};
export const isValidArabicLetter = (htmlContent) => {
  if (!htmlContent) return true;
  const regex = /^[\u0600-\u06FF\s]+$/;
  return regex.test(htmlContent);
};
