<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content top-space">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-10 mx-auto">
            <div class="row">
              <div class="col-sm-6">
                <router-link
                  class="card card text-white bg-info py-5"
                  to="/doctor-register"
                >
                  <h2 class="text-center py-3">
                    {{ $t("register.doctorRegister") }}
                  </h2>
                </router-link>
              </div>
              <div class="col-sm-6">
                <router-link
                  class="card text-white bg-primary py-5"
                  to="/patient-register"
                >
                  <h2 class="text-center py-3">
                    {{ $t("register.patientRegister") }}
                  </h2>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <footerindex />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
