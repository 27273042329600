<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('drDetails')"
      :text="$t('home')"
      :text1="$t('drDetails')"
      :text2="$t('doctors')"
    />
    <div class="container content">
      <div class="d-flex justify-content-center py-5" v-if="loading">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        ></div>
      </div>
      <div v-else-if="!loading && drDetails.id">
        <div class="profile-sidebar patient-sidebar profile-sidebar-new">
          <div class="widget-profile pro-widget-content">
            <div class="profile-info-widget">
              <div class="booking-doc-img">
                <img :src="drDetails.photo" alt="" />
              </div>
            </div>
          </div>
          <div class="px-5">
            <div class="row">
              <div class="mb-3 col-lg-4 col-md-6" v-if="drDetails.job_title_ar">
                <h4>
                  {{ $t("doctor_name") }}
                </h4>
                <p>
                  {{ drDetails.lang }}
                </p>
              </div>
              <div class="mb-3 col-lg-4 col-md-6" v-if="drDetails.job_title">
                <h4>
                  {{ $t("job_title") }}
                </h4>
                <p>
                  {{ drDetails.job_title }}
                </p>
              </div>
              <div class="mb-3 col-lg-4 col-md-6" v-if="drDetails.specialist">
                <h4>
                  {{ $t("specialist") }}
                </h4>
                <p>
                  {{ drDetails.specialist }}
                </p>
              </div>
              <div class="mb-3 col-12" v-if="drDetails.bio_ar">
                <h4>
                  {{ $t("bio") }}
                </h4>
                <p>
                  {{ drDetails.bio }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ $t("noSearchResult") }}
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { publicDoctorDetails } from "@/api/general";

export default {
  name: "doctorDetails",
  data() {
    return {
      loading: false,
      drDetails: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      this.loading = true;

      try {
        const id = this.$route.query.drId;
        const response = await publicDoctorDetails(id);

        if (response && response.data) {
          this.drDetails = response.data;
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching data list:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
h4 {
  text-transform: capitalize;
}
</style>
