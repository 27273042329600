import request from "@/utils/request";

export function register(data) {
  return request({
    url: `/register`,
    method: "post",
    data: data,
  });
}

export function login(data) {
  return request({
    url: `/login`,
    method: "post",
    data: data,
  });
}

export function forgotPassword(data) {
  return request({
    url: `/forget-password`,
    method: "post",
    data: data,
  });
}

export function resetPassword(data) {
  return request({
    url: `/reset-password`,
    method: "post",
    data: data,
  });
}

export function loginWithToken(data) {
  return request({
    url: `/login-with-token`,
    method: "post",
    data: data,
  });
}

export function getTwoFactorAuth() {
  return request({
    url: `/get-2fa`,
    method: "get",
  });
}
export function getCodeByEmail(data) {
  return request({
    url: `/get-code-by-email`,
    method: "post",
    data,
  });
}

export function setTwoFactorAuth(data) {
  return request({
    url: `/set-2fa`,
    method: "post",
    data,
  });
}
