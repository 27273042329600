import request from "@/utils/request";

export function faqList() {
  return request({
    url: `/faqs`,
    method: "get",
  });
}

export function blogsSearch(data) {
  return request({
    url: `/blogs`,
    method: "get",
    params: data,
  });
}

export function blogsDetails(id) {
  return request({
    url: `/blogs/${id}`,
    method: "get",
  });
}

export function getAllDoctors(data) {
  return request({
    url: `/get-all-doctors`,
    method: "get",
    params: data,
  });
}
export function getAdminstrationTeam(data) {
  return request({
    url: `/get-all-administratives`,
    method: "get",
    params: data,
  });
}
export function blogsCategories() {
  return request({
    url: `/blogs/categories`,
    method: "get",
  });
}
export function testimonialsList() {
  return request({
    url: `/testimonials`,
    method: "get",
  });
}

export function publicDoctorDetails(id) {
  return request({
    url: `/get-doctor-details/${id}`,
    method: "get",
  });
}
