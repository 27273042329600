import request from "@/utils/request";

export function patientRegister(data) {
  return request({
    url: `/patient-application`,
    method: "post",
    data: data,
  });
}
export function patientPreviewContract(data) {
  return request({
    url: `/patient-application/preview-contract`,
    method: "post",
    data: data,
  });
}
export function getDoctorSearchList(data) {
  return request({
    url: `/patient/doctors`,
    method: "get",
    params: data,
  });
}

export function RequestAppointment(data) {
  return request({
    url: `/patient/appointments`,
    method: "post",
    data,
  });
}
export function MyAppointments() {
  return request({
    url: `/patient/appointments`,
    method: "get",
  });
}

export function MyAccount() {
  return request({
    url: `/patient/account`,
    method: "get",
  });
}
export function updatePatientData(data) {
  return request({
    url: `/patient/account`,
    method: "post",
    data,
  });
}

export function showAppointment(id) {
  return request({
    url: `/patient/appointments/${id}`,
    method: "get",
  });
}
export function medicalHistoryList() {
  return request({
    url: `/patient/medical-histories`,
    method: "get",
  });
}

export function showMedicalHistory(id) {
  return request({
    url: `/patient/medical-histories/${id}`,
    method: "get",
  });
}

export function reviewsQuestions(id) {
  return request({
    url: `/reviews-questions?id=${id}`,
    method: "get",
  });
}

export function storeReview(id, data) {
  return request({
    url: `/store-review/${id}`,
    method: "post",
    data,
  });
}
