<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb
      :title="$t('aboutUsPage.titleOne')"
      :text1="$t('aboutUsPage.titleOne')"
      :text="$t('home')"
    />

    <div class="page-wrapper">
      <!-- About Us -->
      <section class="about-section">
        <div class="container">
          <div class="col-lg-10 mx-auto">
            <div class="mb-4">
              <div class="section-inner-header about-inner-header">
                <h3>{{ $t("aboutUsPage.titleOne") }}</h3>
              </div>
              <div class="about-content">
                <div class="about-content-details">
                  <p>
                    {{ $t("aboutUsPage.contentOne") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="section-inner-header about-inner-header">
                <h3>{{ $t("aboutUsPage.titleTwo") }}</h3>
              </div>
              <div class="about-content">
                <div class="about-content-details">
                  <p>
                    {{ $t("aboutUsPage.contentTwo") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="section-inner-header about-inner-header">
                <h3>{{ $t("aboutUsPage.titleThree") }}</h3>
              </div>
              <div class="about-content">
                <div class="about-content-details">
                  <p>
                    {{ $t("aboutUsPage.contentThree") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="section-inner-header about-inner-header">
                <h3>{{ $t("aboutUsPage.titleFour") }}</h3>
              </div>
              <div class="about-content">
                <div class="about-content-details">
                  <p>
                    {{ $t("aboutUsPage.contentFour") }}
                  </p>
                  <p>
                    {{ $t("aboutUsPage.contentFour2") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footerindex></footerindex>
    <cursor></cursor>
  </div>
</template>

<script>
import AboutusChoose from "@/assets/json/aboutuschoose.json";
import AboutusDoctors from "@/assets/json/aboutusdoctors";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexTestimonial from "@/assets/json/indextestimonial.json";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      AboutusChoose: AboutusChoose,
      AboutusDoctors: AboutusDoctors,
      title: "About Us",
      text: "Home",
      text1: "About Us",
      IndexTestimonial: IndexTestimonial,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
