<template>
  <header
    class="header header-fixed"
    v-bind:class="{
      'header-custom header-one home-head-one ': true,
      'header-space': isScrolled,
    }"
  >
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img
              src="@/assets/img/Midocean-Digital-Clinic.png"
              class="img-fluid"
              style="height: 60px"
            />
          </router-link>
        </div>
        <div class="main-menu-wrapper d-lg-flex align-items-center gap-3">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/Midocean-logo.png" class="img-fluid" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="javascript:void(0);"
              @click="closeSidebar"
            >
              <i class="fas fa-times"></i>
            </a>
          </div>
          <mainnav></mainnav>

          <a-dropdown overlayStyle="z-index: 99999" :trigger="['click']">
            <a class="ant-dropdown-link" @click.prevent>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-globe"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="2" y1="12" x2="22" y2="12"></line>
                <path
                  d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                ></path>
              </svg>
              {{ handleLangTitile($i18n.locale) }}
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-if="$i18n.locale !== 'ar'"
                  @click="changeLanguage('ar')"
                >
                  العربية
                </a-menu-item>
                <a-menu-item
                  v-if="$i18n.locale !== 'en'"
                  @click="changeLanguage('en')"
                >
                  English
                </a-menu-item>
                <a-menu-item
                  v-if="$i18n.locale !== 'fr'"
                  @click="changeLanguage('fr')"
                >
                  Français
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>

        <ul v-if="isLoggedIn" class="nav header-navbar-rht">
          <li class="nav-item dropdown has-arrow logged-item">
            <a
              href="javascript:;"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img">
                <img class="rounded-circle" :src="userData.photo" width="31" />
              </span>
            </a>
            <div
              class="dropdown-menu"
              :class="$i18n.locale !== 'ar' ? 'dropdown-menu-start' : ''"
            >
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img
                    :src="userData.photo"
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="user-text">
                  <h6>{{ userData.name.slice(0, 10) }}</h6>
                  <p class="text-muted mb-0">{{ userData.email }}</p>
                </div>
              </div>
              <router-link
                v-if="userData.doctorOrPtient == 'isDoctor'"
                class="dropdown-item"
                to="/doctor-dashboard"
                >{{ $t("dashboard") }}</router-link
              >
              <router-link
                v-if="userData.doctorOrPtient == 'isDoctor'"
                class="dropdown-item"
                to="/doctor-profile-settings"
                >{{ $t("profile_settings") }}</router-link
              >
              <router-link
                v-else
                class="dropdown-item"
                to="/profile-settings"
                >{{ $t("profile_settings") }}</router-link
              >
              <router-link
                v-if="userData.doctorOrPtient == 'isDoctor'"
                class="dropdown-item"
                to="/doctor-2fa-settings"
                >{{ $t("security") }}</router-link
              >
              <router-link v-else class="dropdown-item" to="/2fa-setting">{{
                $t("security")
              }}</router-link>
              <button class="dropdown-item" @click="logout">
                {{ $t("logout") }}
              </button>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
      <div
        class="sidebar-overlay"
        :class="{ opened: isSidebarOpen }"
        @click="closeSidebar"
      ></div>
    </div>
  </header>
</template>
<script>
import { getUserLang } from "@/utils/helpers";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isScrolled: false,
      isSidebarOpen: false,
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters(["currentLang", "isLoggedIn", "userData"]),
  },
  beforeMount() {
    const lang = getUserLang();

    this.setDocumentDir(lang);

    this.$i18n.locale = lang;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleLangTitile(locale) {
      if (locale == "ar") {
        return "العربية";
      } else if (locale == "en") {
        return "English";
      } else {
        return "Français";
      }
    },
    setDocumentDir(lang) {
      document.documentElement.setAttribute("lang", lang);

      if (lang === "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        document.querySelector("body").classList.add("rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        document.querySelector("body").classList.remove("rtl");
      }
    },
    changeLanguage(lang) {
      this.setDocumentDir(lang);
      this.$i18n.locale = lang;
      this.$store.dispatch("handleUserLang", lang);
      window.location.reload();
    },
    submitForm() {
      this.$router.push("/doctor-search-list");
    },
    handleScroll() {
      // Get the scroll position
      const scroll = window.scrollY;

      // Update the isScrolled data property based on the scroll position
      this.isScrolled = scroll > 35;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .ant-dropdown-link {
    color: white;
    padding: 20px;
    display: inline-block;
  }
}
</style>
