<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar patient-sidebar profile-sidebar-new">
        <div class="widget-profile pro-widget-content">
          <div class="profile-info-widget">
            <router-link to="/doctor-profile" class="booking-doc-img">
              <img :src="userData?.doctor?.photo" :alt="userData.first_name" />
            </router-link>
            <div class="profile-det-info">
              <h3>
                <router-link to="/doctor-profile"
                  >{{ userData.name.slice(0, 10) }} ..</router-link
                >
              </h3>
              <span
                class="badge doctor-role-badge"
                v-if="userData?.doctor?.specialist"
                >{{ userData?.doctor?.specialist }}</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="doctor-available-head">
          <div class="input-block input-block-new">
            <label class="form-label"
              >Availability <span class="text-danger">*</span></label
            >
            <vue-select
              :options="Available"
              id="available"
              placeholder="I am Available"
            />
          </div>
        </div> -->
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <li :class="{ active: isActive('/doctor-dashboard') }">
                <router-link to="/doctor-dashboard">
                  <span class="ms-1">{{ $t("dashboard") }}</span>
                </router-link>
              </li>
              <!-- <li :class="{ active: isActive('/doctor-request') }">
                <router-link to="/doctor-request">
                  <i class="fa-solid fa-calendar-check"></i>
                  <span>Requests</span>
                  <small class="unread-msg">2</small>
                </router-link>
              </li> -->
              <li
                :class="{
                  active: isActive(['/appointments']),
                }"
              >
                <router-link to="/appointments">
                  <span class="ms-1">{{ $t("appointments") }}</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/available-timings') }">
                <router-link to="/available-timings">
                  <span class="ms-1">{{ $t("available_timings") }}</span>
                </router-link>
              </li>
              <!-- <li
                :class="{
                  active: isActive(['/my-patients', '/patient-profile']),
                }"
              >
                <router-link to="/my-patients">
                  <i class="fa-solid fa-user-injured"></i>
                  <span class="ms-1">My Patients</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/doctor-specialities') }">
                <router-link to="/doctor-specialities">
                  <i class="fa-solid fa-clock"></i>
                  <span>Specialties & Services</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/reviews') }">
                <router-link to="/reviews">
                  <i class="fas fa-star"></i>
                  <span class="ms-1">Reviews</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/accounts') }">
                <router-link to="/accounts">
                  <i class="fa-solid fa-file-contract"></i>
                  <span class="ms-1">Accounts</span>
                </router-link>
              </li> -->
              <li
                :class="{
                  active: isActive(['/doctor-profile-settings']),
                }"
              >
                <router-link to="/doctor-profile-settings">
                  <span class="ms-1">{{ $t("profile_settings") }}</span>
                </router-link>
              </li>
              <li
                :class="{
                  active: isActive(['/doctor-2fa-settings']),
                }"
              >
                <router-link to="/doctor-2fa-settings">
                  <span class="ms-1">{{ $t("security") }}</span>
                </router-link>
              </li>
              <!-- <li :class="{ active: isActive('/social-media') }">
                <router-link to="/social-media">
                  <i class="fa-solid fa-shield-halved"></i>
                  <span class="ms-1">Social Media</span>
                </router-link>
              </li> -->
              <li @click="logout" :class="{ active: isActive('/') }">
                <router-link to="/">
                  <span class="ms-1">{{ $t("logout") }}</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeClass: "active",
      Available: [this.$t("availableNow"), this.$t("notAvailable")],
    };
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
