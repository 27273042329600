<template>
  <div class="stickysidebar">
    <div class="profile-sidebar patient-sidebar profile-sidebar-new">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <router-link to="/profile-settings" class="booking-doc-img">
            <img :src="userData?.patient?.photo" :alt="userData.first_name" />
          </router-link>
          <div class="profile-det-info">
            <h3>
              <router-link to="/profile-settings"
                >{{ userData.name.slice(0, 10) }} ..</router-link
              >
            </h3>
            <span>
              {{ userData?.patient?.gender }}
              <i class="fa-solid fa-circle"></i>
              <span v-if="userData?.patient?.age">
                {{ userData.patient.age }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="dashboard-widget">
        <nav class="dashboard-menu">
          <ul>
            <li :class="{ active: isActive('/my-appointment') }">
              <router-link to="/my-appointment">
                <span>{{ $t("myAppointments") }}</span>
              </router-link>
            </li>
            <li :class="{ active: isActive('/profile-settings') }">
              <router-link to="/profile-settings">
                <span>{{ $t("profile_settings") }}</span>
              </router-link>
            </li>
            <li :class="{ active: isActive('/medical-details') }">
              <router-link to="/medical-details">
                <span>{{ $t("medical_details") }}</span>
              </router-link>
            </li>
            <li :class="{ active: isActive('/2fa-setting') }">
              <router-link to="/2fa-setting">
                <span>{{ $t("security") }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeClass: "active",
    };
  },
  computed: {
    ...mapGetters(["currentLang", "isLoggedIn", "userData"]),
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
  },
};
</script>
