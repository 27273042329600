<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb
      :title="$t('articleDetails')"
      :text="$t('home')"
      :text1="$t('articleDetails')"
    />

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="blog-view">
                <div class="blog blog-single-post">
                  <div class="blog-image">
                    <a href="javascript:void(0);">
                      <div class="col-lg-6 col-md-10 mx-auto">
                        <img
                          alt="blog-image"
                          :src="blogDetails.image"
                          class="img-fluid"
                        /></div
                    ></a>
                  </div>
                  <h3 class="blog-title d-flex justify-content-between">
                    <span>
                      {{ blogDetails.lang }}
                    </span>
                    <a style="font-size: 40px" @click.prevent="$router.go(-1)">
                      {{ $i18n.locale == "ar" ? "←" : "→" }}
                    </a>
                  </h3>
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <div class="post-author">
                            <router-link to="/doctor-profile"
                              ><img
                                src="@/assets/img/doctors/doctor-thumb-02.jpg"
                                alt="Post Author"
                              />
                              <span>
                                {{ blogDetails.author }}
                              </span></router-link
                            >
                          </div>
                        </li>
                        <li>
                          <i class="far fa-calendar"></i> {{ blogDetails.date }}
                        </li>
                        <!-- <li><i class="far fa-comments"></i>12 Comments</li> -->
                        <li>
                          <i class="fa fa-tags"></i
                          >{{ blogDetails?.category?.lang }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="blog-content">
                    <p>
                      <b v-html="blogDetails.sub_description"> </b>
                    </p>
                    <p
                      v-html="blogDetails.description"
                      style="line-height: 30px; font-size: 20px"
                    ></p>
                  </div>
                </div>

                <!-- <div class="card blog-share clearfix" >
                  <div class="card-header">
                    <h4 class="card-title">Share the post</h4>
                  </div>
                  <div class="card-body">
                    <ul class="social-share">
                      <li>
                        <a href="javascript:;" title="Facebook"
                          ><i class="fab fa-facebook"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Twitter"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Linkedin"
                          ><i class="fab fa-linkedin"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Google Plus"
                          ><i class="fab fa-google-plus"></i
                        ></a>
                      </li>
                      <li>
                        <a href="javascript:;" title="Youtube"
                          ><i class="fab fa-youtube"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- <div class="card author-widget clearfix">
                  <div class="card-header">
                    <h4 class="card-title">About Author</h4>
                  </div>
                  <div class="card-body">
                    <div class="about-author">
                      <div class="about-author-img">
                        <div class="author-img-wrap">
                          <router-link to="/doctor-profile"
                            ><img
                              class="img-fluid rounded-circle"
                              alt="Darren Elder"
                              src="@/assets/img/doctors/doctor-thumb-02.jpg"
                          /></router-link>
                        </div>
                      </div>
                      <div class="author-details">
                        <router-link
                          to="/doctor-profile"
                          class="blog-author-name"
                          >Dr. Darren Elder</router-link
                        >
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { blogsDetails } from "@/api/general";

export default {
  data() {
    return {
      title: "Blog Details",
      text: "Home",
      text1: "Blog Details",
      blogDetails: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      this.loading = true;

      try {
        const response = await blogsDetails(this.$route.query.blogId);

        if (response) {
          if (response) {
            this.blogDetails = response.data.blog;
          }
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
