<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('serviceGuide')"
      :text="$t('home')"
      :text1="$t('serviceGuide')"
    />
    <div class="">
      <div class="d-flex justify-content">
        <iframe
          style="flex-grow: 1; height: 100vh"
          src="https://www.youtube.com/embed/DKeJTW8mD7o?si=dD8nAy8vzOWSddYM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>
