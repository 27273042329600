<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('administrativeTeam')"
      :text="$t('home')"
      :text1="$t('administrativeTeam')"
    />
    <div class="container content">
      <div class="d-flex justify-content-center py-5" v-if="loading">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        ></div>
      </div>
      <div class="row" v-else-if="!loading && searchResult.length">
        <div
          class="col-lg-3 col-md-4 col-sm-6"
          v-for="(item, i) in searchResult"
          :key="i"
        >
          <div class="card h-100">
            <img class="doctor-image" :src="item.photo" alt="admin Image" />
            <h2 class="doctor-name">{{ item.name }}</h2>
            <p>
              {{ item.job_title.slice(0, 25) }}
              {{ item.job_title.length >= 25 ? "..." : "" }}
            </p>
          </div>
        </div>
        <pagination
          :pagination="pagination"
          @changePage="gotoPage"
        ></pagination>
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ $t("noSearchResult") }}
      </div>
    </div>

    <footerindex></footerindex>
  </div>
</template>

<script>
import { getAdminstrationTeam } from "@/api/general";
import pagination from "@/components/frontend/pagination/index.vue";

export default {
  name: "DoctorCard",
  data() {
    return {
      loading: false,
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
      searchResult: [],
      searchModel: {
        page: 1,
      },
    };
  },
  components: {
    pagination,
  },
  created() {
    this.loadSearchModelFromQuery();
    this.getDataList();
  },
  methods: {
    loadSearchModelFromQuery() {
      const query = this.$route.query;
      if (query && query.page) {
        this.searchModel.page = Number(query.page);
      }
    },
    async getDataList() {
      this.loading = true;

      try {
        const response = await getAdminstrationTeam();

        if (response && response.data) {
          this.searchResult = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
          this.updateQueryParams();
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching data list:", error);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams() {
      this.$router.push({
        query: {
          page: this.searchModel.page,
        },
      });
    },
    gotoPage(page) {
      const pageNumber = Number(page);
      if (pageNumber > 0 && pageNumber <= this.pagination.totalPages) {
        this.pagination.pageNumber = pageNumber;
        this.$router.push({
          query: { ...this.$route.query, page: pageNumber },
        });
        this.getDataList();
      } else {
        console.warn("Invalid page number:", pageNumber);
      }
    },
  },
};
</script>

<style scoped>
.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
}

.doctor-image {
  border-radius: 10px;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  margin-bottom: 15px;
}

.tags {
  display: flex;
  margin-bottom: 10px;
}
b {
  display: block;
  text-align: start;
}
p {
  text-align: start;
  color: #727272;
}
.tag {
  font-size: 14px;
}

.doctor-name {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  text-align: start;
}

.specialty {
  color: #666;
  font-size: 16px;
  text-align: start;
}
h2 {
  color: #0e82fd;
}
</style>
