export const isValidEnglishContent = (htmlContent) => {
  if (!htmlContent) return true;
  const regex = /^[ -.():،/_a-zA-Z0-9-\n]+$/; // numbers + speicial chracters(-.():،) sapce enter english character
  return regex.test(htmlContent);
};

export const isValidEnglishLetter = (htmlContent) => {
  if (!htmlContent) return true;
  const regex = /^[a-zA-Z\s]+$/;
  return regex.test(htmlContent);
};

export const isValidFrenchLetter = (htmlContent) => {
  if (!htmlContent) return true;
  const regex = /^[a-zA-ZÀ-ÿ\s]+$/;
  return regex.test(htmlContent);
};
