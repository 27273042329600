<template>
  <div id="app">
    <router-view v-if="isStyleLoaded" />

    <!-- <a-modal
      v-model:open="responseMessage.open"
      :title="$t('notice')"
      centered
      :footer="null"
      @cancel="
        responseMessage.open = false;
        responseMessage.message = '';
      "
    >
      {{ responseMessage.message }}
    </a-modal> -->
  </div>
</template>
<script>
import { loadStyles, loadRtlStyles } from "@/loadStyles";
import store from "./store";
import { mapState } from "vuex";
import { notification } from "ant-design-vue";
import { getUserLang } from "@/utils/helpers";
export default {
  name: "App",
  setup() {
    const lang = getUserLang() || "en";
    if (lang === "ar") {
      loadRtlStyles();
    } else {
      loadStyles();
    }
    store.dispatch("handleAutoLogin");
    store.commit("GET_CURRENT_DATE");
    return {};
  },
  computed: {
    ...mapState(["responseMessage"]),
  },
  data() {
    return {
      isStyleLoaded: false,
    };
  },
  created() {
    setTimeout(() => {
      this.isStyleLoaded = true;
    }, 1000);
  },
  methods: {
    openNotification() {
      notification.open({
        message: this.$t("notice"),
        description: this.responseMessage.message,
        duration: 2,
        placement: "bottomRight",
      });
    },
  },
  watch: {
    responseMessage(newVal) {
      if (newVal) {
        console.log();
        this.openNotification();
      }
    },
  },
};
</script>
