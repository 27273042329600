<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader />
    <breadcrumb1
      :title="this.$t('security')"
      :text="this.$t('home')"
      :text1="this.$t('security')"
    />
    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <doctorsidebar></doctorsidebar>
          <!-- /Profile Sidebar -->
          <div class="col-lg-8 col-xl-9">
            <twoFactorSetting></twoFactorSetting>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>
<script>
import twoFactorSetting from "@/components/twoFactorSetting/index.vue";
export default {
  components: {
    twoFactorSetting,
  },
};
</script>
