<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('medical_history')"
      :text="this.$t('home')"
      :text1="this.$t('medical_history')"
    />

    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Profile Sidebar -->
            <profilesidebar></profilesidebar>
            <!-- /Profile Sidebar -->
          </div>
          <!-- / Profile Sidebar -->

          <div class="col-lg-8 col-xl-9">
            <div class="d-flex justify-content-center py-5" v-if="loading">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              ></div>
            </div>
            <div v-else-if="!loading && historyList?.length">
              <div class="row">
                <div
                  class="col-lg-6"
                  v-for="item in historyList"
                  :key="item.id"
                >
                  <div class="card h-100">
                    <div class="card-body">
                      <h5 class="card-title mb-1">{{ item.doctor_name }}</h5>
                      <small>
                        {{ item.date }}
                      </small>
                      <p class="card-text mt-3">
                        {{ item.diagnosis }}
                      </p>
                      <a
                        @click="handleDetails(item)"
                        class="btn bg-info"
                        href="#history_details"
                        data-bs-toggle="modal"
                      >
                        {{ $t("details") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <pagination
                :pagination="pagination"
                @changePage="gotoPage"
              ></pagination>
            </div>
            <div class="d-flex justify-content-center py-5" v-else>
              {{ $t("noSearchResult") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
    <Teleport to="body">
      <div
        class="modal fade custom-modal custom-modal-two modal-lg"
        id="history_details"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t("history_details") }}</h5>
              <button type="button" data-bs-dismiss="modal" aria-label="Close">
                <span><i class="fa-solid fa-x"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="d-flex justify-content-center py-5"
                v-if="showloading"
              >
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                ></div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("doctor_name") }}</h4>
                    <p>
                      {{ historyDetails.doctor_name }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("date") }}</h4>
                    <p>
                      {{ historyDetails?.date }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("diagnosis") }}</h4>
                    <p>
                      {{ historyDetails?.diagnosis }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
<script>
import { medicalHistoryList, showMedicalHistory } from "@/api/patient";
import pagination from "@/components/frontend/pagination/index.vue";

import medical_details from "@/assets/json/patients/medical-details.json";
export default {
  data() {
    return {
      title: "Medical History",
      text: "Home",
      text1: "Medical History",
      medical_details: medical_details,
      historyList: [],
      showloading: false,
      loading: false,
      historyDetails: {},
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
    };
  },
  name: "Medical History",
  created() {
    this.getMedicalHistories();
  },
  components: {
    pagination,
  },
  methods: {
    async getMedicalHistories() {
      this.loading = true;
      try {
        let response = await medicalHistoryList();
        if (response) {
          this.historyList = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    gotoPage(page) {
      this.pagination.pageNumber = Number(page);
      this.$router.push({ query: { ...this.$route.query, page: page } });
      this.getMedicalHistories();
    },
    async handleDetails(item) {
      this.showloading = true;
      try {
        let response = await showMedicalHistory(item.id);
        if (response) {
          this.historyDetails = response.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.showloading = false;
      }
    },
  },
};
</script>
