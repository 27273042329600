<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 col-lg-10 mx-auto">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-lg-6 col-lg-8 mx-auto">
                    <div class="login-header">
                      <h3>
                        {{ $t("register.forgotPassword") }}
                      </h3>
                      <p class="small text-muted">
                        {{ $t("register.EnterEmailToPasswordResetLink") }}
                      </p>
                    </div>

                    <!-- Forgot Password Form -->
                    <form @submit.prevent="resetPassword">
                      <div class="mb-3 form-focus">
                        <input
                          type="text"
                          placeholder=" "
                          class="form-control floating"
                          v-model="code"
                        />
                        <label class="focus-label">{{
                          $t("sendedCode")
                        }}</label>
                      </div>
                      <div class="py-2" v-if="v$.code.$error">
                        <small
                          v-if="v$.code.required.$invalid"
                          class="text-danger d-block"
                        >
                          {{ $t("validation.requiredField") }}
                        </small>
                      </div>

                      <div class="mb-3 form-focus">
                        <input
                          type="password"
                          placeholder=" "
                          class="form-control floating"
                          v-model="password"
                        />
                        <label class="focus-label">{{ $t("password") }}</label>
                      </div>

                      <div class="py-2" v-if="v$.password.$error">
                        <small
                          v-if="v$.password.required.$invalid"
                          class="text-danger d-block"
                        >
                          {{ $t("validation.requiredField") }}
                        </small>
                      </div>

                      <div class="mb-3 form-focus">
                        <input
                          type="password"
                          placeholder=" "
                          class="form-control floating"
                          v-model="password_confirmation"
                        />
                        <label class="focus-label">{{
                          $t("confirmPassword")
                        }}</label>
                      </div>

                      <div class="py-2" v-if="v$.password_confirmation.$error">
                        <small
                          v-if="v$.password_confirmation.required.$invalid"
                          class="text-danger d-block"
                        >
                          {{ $t("validation.requiredField") }}
                        </small>
                        <small
                          v-if="
                            v$.password_confirmation.sameAsPassword.$invalid &&
                            password_confirmation
                          "
                          class="text-danger d-block"
                        >
                          {{ $t("validation.notSameAsPassword") }}
                        </small>
                      </div>

                      <div class="action">
                        <b-button
                          class="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                          :disabled="loading"
                        >
                          <span v-if="!loading">
                            {{ $t("register.resetPassword") }}
                          </span>
                          <b-spinner v-else></b-spinner>
                        </b-button>
                      </div>
                    </form>
                    <!-- /Forgot Password Form -->
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { required, sameAs } from "@vuelidate/validators";
import { resetPassword } from "@/api/user";
import { getForgotMail, removeForgotMail } from "@/utils/auth";
import useVuelidate from "@vuelidate/core";

export default {
  layout: "auth",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      code: "",
      password: "",
      password_confirmation: "",
      loading: false,
    };
  },
  validations() {
    return {
      code: {
        required,
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs(this.password),
      },
    };
  },
  computed: {
    isUserLang() {
      return this.$store.getters.isUserLang;
    },
  },
  created() {
    // this.$bvToast.toast(this.$t("checkMail"), {
    //   title: this.$t("notice"),
    //   autoHideDelay: 3000,
    //   variant: "success",
    //   noCloseButton: true,
    //   solid: true,
    // });
  },
  methods: {
    async resetPassword() {
      await this.v$.$validate();
      if (this.v$.$invalid) {
        return;
      }
      this.loading = true;

      let payload = new FormData();
      payload.append("code", this.code);
      payload.append("password", this.password);
      payload.append("password_confirmation", this.password_confirmation);
      payload.append("email", getForgotMail(this.$cookies));

      let response = await resetPassword(payload, this.isUserLang);

      if (response) {
        removeForgotMail(this.$cookies);
        this.$router.push(`/login`);
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
