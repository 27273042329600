<template>
  <layoutheader />
  <indexbanner />
  <indexwork />
  <!-- <indexapp /> -->
  <indextestimonial />
  <!-- <indexpartners /> -->
  <!-- <cursor /> -->
  <footerindex />
  <scrolltotop />
</template>
